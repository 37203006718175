<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="訂單編號" prop="orderNo">
                <a-input v-model="queryParam.orderNo" placeholder="请输入訂單編號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="用戶昵稱" prop="userId">
                <a-input v-model="queryParam.userName" placeholder="请输入用戶昵稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="用戶手機號" prop="mobile">
                <a-input v-model="queryParam.mobile" placeholder="请输入用戶手機號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="刷手號" prop="userNum">
                <a-input v-model="queryParam.userNum" placeholder="请输入刷手號" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="支付類型" prop="paymentType">
                <a-select v-model="queryParam.paymentType">
<!--                  <a-select-option :value="2">-->
<!--                    微信-->
<!--                  </a-select-option>-->
<!--                  <a-select-option :value="3">-->
<!--                    支付宝-->
<!--                  </a-select-option>-->
                  <a-select-option :value="4">
                    苹果内购
                  </a-select-option>
                  <a-select-option :value="5">
                    Google
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="訂單狀態" prop="orderStatus">
                <a-select v-model="queryParam.orderStatus">
                  <a-select-option :value="1">
                    待付款
                  </a-select-option>
                  <a-select-option :value="2">
                    已完成
                  </a-select-option>
<!--                  <a-select-option :value="3">
                    已关闭
                  </a-select-option>-->
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="訂單生成時間" prop="createTime">
                <a-range-picker format="YYYY-MM-DD" v-model="creatRanges" @change="changeCreate"></a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="訂單完成時間" prop="finishTime">
               <a-range-picker format="YYYY-MM-DD" v-model="finishRanges" @change="changeFinish"></a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="handleExport" v-hasPermi="['platform:typeLabel:export']">
          <a-icon type="download" />導出
        </a-button>
        <a-button type="primary" @click="handleTotalExport">
          <a-icon type="download" />匯總
        </a-button>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="finishTime" slot-scope="text, record">
          {{ parseTime(record.finishTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageUserRecharge,listUserRecharge, delUserRecharge } from '@/api/platform/userRecharge'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'UserRecharge',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      creatRanges: [],
      finishRanges: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        orderNo: null,
        userId: null,
        rechargeMoney: null,
        paymentType: null,
        paymentNo: null,
        outTradeNo: null,
        orderStatus: null,
        payTime: null,
        finishTime: null,
        type: null,
        startFinishTime: null,
        endFinishTime: null,
        startCreateTime:null,
        endCreateTime:null,
        userNum:null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '訂單編號',
          dataIndex: 'orderNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶昵稱',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用戶手機號',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '刷手號',
          dataIndex: 'userNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '充值金額',
          dataIndex: 'rechargeMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '支付類型',
          dataIndex: 'paymentType',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 2){
              return "微信"
            }
            if (value == 3){
              return "支付宝"
            }
            if (value == 4){
              return "苹果内购"
            }
            if (value == 5){
              return "Google"
            }
          }
        },
        {
          title: '訂單狀態',
          dataIndex: 'orderStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value == 1){
              return '待付款'
            }
            if (value == 2){
              return '已完成'
            }
            if (value == 3){
              return '已关闭'
            }
          }
        },
        {
          title: '訂單生成時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '訂單完成時間',
          dataIndex: 'finishTime',
          scopedSlots: { customRender: 'finishTime' },
          ellipsis: true,
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢用戶充值列表 */
    getList () {
      this.loading = true
     pageUserRecharge(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        orderNo: undefined,
        userId: undefined,
        rechargeMoney: undefined,
        paymentType: undefined,
        paymentNo: undefined,
        outTradeNo: undefined,
        orderStatus: undefined,
        payTime: undefined,
        finishTime: undefined,
        type: undefined,
        startFinishTime: undefined,
        endFinishTime: undefined,
        startCreateTime: undefined,
        endCreateTime: undefined,
        userNum:undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.creatRanges =  undefined;
      this.finishRanges = undefined;
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    changeCreate(date, dateString) {
      if(dateString.length <= 0){
        return
      }
      console.log(dateString)
      this.queryParam.startCreateTime = dateString[0]+" 00:00:00";
      this.queryParam.endCreateTime = dateString[1]+" 23:59:59";
      //this.handleQuery();
    },
    changeFinish(date, dateString){
      console.log(dateString)
      this.queryParam.startFinishTime = dateString[0]+" 00:00:00";
      this.queryParam.endFinishTime = dateString[1]+" 23:59:59";
      //this.handleQuery();
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delUserRecharge(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    handleTotalExport(){

      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/user-recharge/exportSummary', {
            ...that.queryParam
          }, `用戶充值匯總_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/user-recharge/export', {
            ...that.queryParam
          }, `用戶充值_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
